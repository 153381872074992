<template>
  <div class="page page-tools">
    <Nav />
    <router-view />
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'

export default {
  name: 'Tools',
  components: {
    Nav
  },
  methods: {},
  mounted () {}
}
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.page {
  padding-top: 50px;
}
h1 {
  margin-bottom: 10px;
  text-align: left;
  color: $key-yellow;
  font-size: 2.8em;
  font-weight: 700;
}
@media (max-width: 576px) {
  .page {
    // padding-top: 100px;
  }
}
</style>
